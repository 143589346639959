
import { Options, Vue, Prop, Inject } from "vue-property-decorator";
import { PropType } from "vue";
import { IPriceByStudent } from "@/interfaces/IPriceByStudent";

@Options({})
export default class EducationPriceBlock extends Vue {
  public name = "EducationPriceBlock";

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop({ type: Array as PropType<Array<IPriceByStudent>> })
  public pricesByStudent!: Array<IPriceByStudent>;

  public studentEducationRelatedToCostBearer(pricesByStudent: IPriceByStudent): boolean {
    return Boolean(pricesByStudent?.educationWithCostBearer);
  }

  @Inject({ from: "updateSlideHeight", default: () => null })
  public updateSlideHeight!: () => any;

  public onChangeTab() {
    this.updateSlideHeight();
  }
}
