
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({
  components: {},
})
export default class EducationLessonBKFFormat extends Vue {
  public name = "EducationLessonFormatBKF.vue";

  @Prop()
  public title!: any;

  @Prop()
  public knowledgeAreas!: any;

  @Prop()
  public minutesBooked!: any;

  @Prop()
  public minutesPlanned!: any;

  @Prop()
  public date!: any;
}
